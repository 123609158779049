'use client';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { CDN_BASE } from '@/common/constant';
import { useParams } from 'next/navigation';
import '../../styles/error.scss';

// 文案
const context = {
    cn: {
        title: '看起来误闯编辑办公室的你迷路了',
        content: '您可以返回首页或使用搜索功能寻找其他相关新闻',
        back: '返回首页',
    },
    tw: {
        title: '看起來誤闖編輯辦公室的你迷路了',
        content: '您可以返回首頁或使用搜索功能尋找其他相關新聞',
        back: '返回首頁',
    },
};

const ErrorComponent = () => {
    const { locale } = useParams();
    const [imageSrc, setImageSrc] = useState(null);

    // 處理隨機圖片
    const handleImage = () => {
        const randomNumber = Math.floor(Math.random() * 4) + 1;

        setImageSrc(`/images/layout/error_${randomNumber}.png`);
    };

    useEffect(() => {
        handleImage();
    }, []);

    return (
        <main className="w-full max-w-[1440px] mx-auto relative pb-[60px]">
            {/* 背景 */}
            <div className="absolute top-0 left-[50%] translate-x-[-50%] w-screen z-[0] bg-black1 py-[75px] flex items-center overflow-hidden">
                <div className="w-screen flex autoCarousel">
                    <div className="img-box w-[1920px] aspect-[1342/112] opacity-[.3] after:content-none dark:filter-white">
                        <Image
                            width={1342}
                            height={112}
                            src={CDN_BASE + 'layout/error_bg.svg'}
                            alt="background"
                            className="w-full h-auto"
                        />
                    </div>
                    <div className="img-box w-[1920px] aspect-[1342/112] opacity-[.3] after:content-none dark:filter-white">
                        <Image
                            width={1342}
                            height={112}
                            src={CDN_BASE + 'layout/error_bg.svg'}
                            alt="background"
                            className="w-full h-auto"
                        />
                    </div>
                </div>
            </div>

            <div
                className="pt-[40px] text-center mx-auto w-full max-w-[502px] space-y-[7px] text-black
            ulg:[&>p]:px-[15px]"
            >
                <div className="img-box w-full aspect-[424/289] after:content-none">
                    {imageSrc && (
                        <Image
                            src={imageSrc}
                            priority={true}
                            loading="eager"
                            width={424}
                            height={289}
                            className="w-full h-auto object-contain"
                            alt="error"
                        />
                    )}
                </div>
                <p className="text-[4rem] font-[900] leading-[100%]">OOPS!</p>
                <p className="text-bd6b">Looks like you got lost.</p>
                <p className="text-bd5b">{context?.[locale]?.title}</p>
                <p className="text-bd2b">{context?.[locale]?.content}</p>

                {/* 返回首頁 */}
                <Link
                    href="/"
                    className="inline-block border-1 border-black  text-btn1 w-[260px] py-[16px] rounded-[40px] !mt-[40px] bg-black text-white trans
          hover:bg-white hover:text-black "
                >
                    {context?.[locale]?.back || '返回首頁'}
                </Link>
            </div>
        </main>
    );
};

export default ErrorComponent;
